import { Component, OnInit } from '@angular/core';
import { FilterConditionType, FilterOperatorType } from '@suvo-bi-lib';
import { chartColors } from 'apps/mba-cpr-survey-portal/src/app/shared/const/chart-colors.const';
import { IChartTileDefinition } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/dashboards/interfaces/tiles/definitions/chart-tile-definition.interface';

@Component({
  selector: 'app-analyst-report',
  templateUrl: './analyst-report.component.html',
  styleUrls: ['./analyst-report.component.scss'],
})
export class AnalystReportComponent implements OnInit {
  constructor() {}

  tileDefinitions: Array<IChartTileDefinition> = [
    {
      tileType: 'chart',
      chartType: {
        value: 'bar-vertical',
      },
      title: 'Unfinalised Samples per Analyst',
      recipe: {
        fullRoute: 'sample-reports',
        groupBy: 'userId',
        groupLabelField: 'legacyAnalystId',
        metric: 'count',
        maxGroups: 20,
        includeOtherGroup: true,
        filters: {
          name: 'report filters',
          query: {
            op: FilterOperatorType.And,
            rules: [
              {
                key: 'finalisedAt',
                con: [{ type: FilterConditionType.NotExists }],
              },
              {
                key: 'user.legacyAnalystId',
                neq: 99,
              },
            ],
          },
        },
      },
      chartColors: [chartColors[2]],
      canView: true,
      hideLegend: true,
    },
    {
      tileType: 'chart',
      chartType: {
        value: 'bar-vertical',
      },
      title: 'Unresolved checkblocks per Analyst',
      recipe: {
        fullRoute: 'check-block-reports',
        groupBy: 'sample.userId',
        groupLabelField: 'legacyAnalystId',
        metric: 'count',
        maxGroups: 20,
        includeOtherGroup: true,
        filters: {
          name: 'report filters',
          query: {
            op: FilterOperatorType.And,
            rules: [
              {
                op: FilterOperatorType.Or,
                rules: [
                  {
                    key: 'resolved',
                    con: [{ type: FilterConditionType.Equals, val: false }],
                  },
                  {
                    key: 'resolved',
                    con: [{ type: FilterConditionType.NotExists }],
                  },
                ],
              },
              {
                key: 'sampleUser.legacyAnalystId',
                neq: 99,
              },
            ],
          },
        },
      },
      chartColors: [chartColors[4]],
      canView: true,
      hideLegend: true,
    },
  ];

  ngOnInit(): void {}
}
