import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TourDialogComponent } from './tour-dialog/tour-dialog.component';
import { TowsService } from './tows/service/tows.service';
import { ReferenceLookupService } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/references/services/reference-lookup-service.service';
import { ShipsService } from './ships/service/ships.service';
import { RoutesService } from './routes/service/routes.service';
import { CprsService } from './cprs/service/cprs.service';
import { MicroscopeService } from './microscopes/service/microscope.service';
import { ITableOptions } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-options.interface';
import { STATUSES } from '../../shared/components/status-chip/status.type';
import { UsersService } from './users/service/users.service';

const INTRO_KEY = 'intro-shown';
/** If we have a major update to the intro tour dialog, increment this to show it to all users again. */
const INTRO_VERSION = '2';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateComponent implements OnInit {
  constructor(
    private readonly dialog: MatDialog,
    private towsService: TowsService,
    private shipsService: ShipsService,
    private routesService: RoutesService,
    private cprsService: CprsService,
    private microscopeService: MicroscopeService,
    private referenceLookupService: ReferenceLookupService,
    private usersService: UsersService,
  ) {}

  ngOnInit() {
    // // Tour is disabled for narrow screen (mobile) for now
    // if (window.innerWidth > 600 && localStorage.getItem(INTRO_KEY) !== INTRO_VERSION) {
    //   this.dialog.open(TourDialogComponent);
    //   localStorage.setItem(INTRO_KEY, INTRO_VERSION);
    // }

    //Set up the reference providers //TODO : Consider if there is a better place to do this!
    this.initReferenceLookupService();
  }

  async initReferenceLookupService() {
    let towStatusService = {
      getOne: (suffix: string | string[] = '', id: string) => {
        return id;
      },
      getPaginated: (suffix: string | string[] = '', options: ITableOptions) => {
        return {
          data: STATUSES.map((status) => {
            return {
              _id: status,
              data: {
                name: status,
              },
            };
          })
            .filter((s) => {
              if (!options.search) {
                return true;
              }

              return s.data.name.toLowerCase().includes(options.search.toLowerCase());
            })
            .sort((a, b) => {
              if (a.data.name < b.data.name) {
                return -1;
              } else {
                return 1;
              }
            }),
        };
      },
    };

    let towDefinition = {
      id: 'tow',
      name: 'Tow',
      referenceLabelPropertyKey: 'metadata.oldTowBaseId',
    };
    let towStatusDefinition = {
      id: 'tow-status',
      name: 'Tow Status',
    };
    let shipDefinition = { id: 'ship', name: 'Ship', referenceLabelPropertyKey: 'name' };
    let routeDefinition = {
      id: 'route',
      name: 'Route',
      referenceLabelPropertyKey: 'oldRouteComplexId',
    };
    let cprDefinition = { id: 'cpr', name: 'CPR', referenceLabelPropertyKey: 'oldCprId' };
    let microscopeDefinition = {
      id: 'microscope',
      name: 'Microscope',
      referenceLabelPropertyKey: 'label',
    };
    let userDefinition = {
      id: 'users',
      name: 'Analyst',
      referenceLabelPropertyKey: 'name',
    };

    this.referenceLookupService.addReferenceProvider('tows', towDefinition, this.towsService);
    this.referenceLookupService.addReferenceProvider(
      'tow-status',
      towStatusDefinition,
      towStatusService,
    );
    this.referenceLookupService.addReferenceProvider('users', userDefinition, this.usersService);
    this.referenceLookupService.addReferenceProvider('ships', shipDefinition, this.shipsService);
    this.referenceLookupService.addReferenceProvider('routes', routeDefinition, this.routesService);
    this.referenceLookupService.addReferenceProvider('cprs', cprDefinition, this.cprsService);
    this.referenceLookupService.addReferenceProvider(
      'microscopes',
      microscopeDefinition,
      this.microscopeService,
    );
  }
}
